import {
  Box,
  Button,
  Divider,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomDialog } from "../../components/custom-dialog";
import Iconify from "../../components/iconify";
import { useGetMeQuery } from "../../core/user/user.query";
import Image from "../../components/image";
import { linkMess } from "../../constants/link";
import { useGetBankInfoQuery } from "../../core/bank-info/bank-info.query";
import { HOST_URL } from "../../constants/main";

const ATMExchangeDialog = ({ open, onClose }) => {
  const { data } = useGetMeQuery();
  const { data: bankInfo } = useGetBankInfoQuery();

  const [user, setUser] = useState(null);
  const [bank, setBank] = useState(null);

  useEffect(() => {
    if (!data) return;
    setUser(data);
  }, [data]);

  useEffect(() => {
    if (!bankInfo) return;
    setBank(bankInfo);
  }, [bankInfo]);

  return (
    <CustomDialog
      size={"xs"}
      title={"Chuyển khoản qua ATM"}
      open={open}
      onClose={() => {
        onClose();
      }}
      content={
        <Stack spacing={0.25} pb={3.5} mt={1}>
          <Typography variant="subtitle2">
            THÔNG TIN TÀI KHOẢN NGÂN HÀNG
          </Typography>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack spacing={0.25}>
              <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                <Typography
                  variant="subtitle2"
                  color={"primary"}
                  textTransform={"uppercase"}
                >
                  NGÂN HÀNG
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={"primary"}
                  textTransform={"uppercase"}
                >
                  :
                </Typography>
                <Typography variant="subtitle2" color="primary">
                  {bank?.bank_name}
                </Typography>
              </Stack>

              <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                <Typography
                  variant="subtitle2"
                  color={"primary"}
                  textTransform={"uppercase"}
                >
                  Số Tài Khoản
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={"primary"}
                  textTransform={"uppercase"}
                >
                  :
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={"primary"}
                  marginRight={2}
                >
                  {bank?.account_number}
                </Typography>
                <Tooltip title="Sao chép" arrow>
                  <Button
                    color="primary"
                    sx={{
                      padding: 0,
                      minWidth: 0,
                      width: 24,
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(bank?.account_number);
                    }}
                  >
                    <Iconify icon="fa-solid:copy" width="24"></Iconify>
                  </Button>
                </Tooltip>
              </Stack>

              <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                <Typography
                  variant="subtitle2"
                  color={"primary"}
                  textTransform={"uppercase"}
                >
                  CHỦ TÀI KHOẢN
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={"primary"}
                  textTransform={"uppercase"}
                >
                  :
                </Typography>
                <Typography variant="subtitle2" color="primary">
                  {bank?.account_holder}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Image
              src={[HOST_URL, bank?.qr_image].join("")}
              sx={{
                width: 250,
              }}
            ></Image>
          </Stack>

          <Divider sx={{ my: 1 }}></Divider>

          <Stack spacing={0.25}>
            <Typography variant="subtitle2">Nội dung chuyển khoản: </Typography>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              p={0.5}
              pl={1}
              sx={{
                border: 2,
                borderColor: "primary.main",
                borderRadius: 0.5,
                borderStyle: "dashed",
              }}
            >
              <Typography variant="subtitle1" color="primary" fontSize={20}>
                id {user?.id}
              </Typography>
              <Tooltip title="Sao chép" arrow>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    navigator.clipboard.writeText(`id ${user.id}`);
                  }}
                >
                  COPY NỘI DUNG
                </Button>
              </Tooltip>
            </Stack>
            <Typography variant="subtitle2" mt={1}>
              <Iconify
                icon="mdi:arrow-up-bold"
                width={20}
                sx={{
                  marginBottom: "-2px",
                }}
              ></Iconify>
              Khi chuyển khoản qua Ngân hàng (ATM) bạn cần ghi nội dung id{" "}
              <Link color={"primary"} variant="subtitle1">
                {user?.id}
              </Link>{" "}
              bên trên.
            </Typography>
            <Typography
              variant="body2"
              color={"primary"}
              fontStyle={"italic"}
              fontWeight={"bold"}
            >
              Lưu ý: Sau khi chuyển khoản xong, hãy chờ "vài phút" rồi ấn{" "}
              <Link fontWeight={900} fontFamily={"system-ui"}>
                "Xác nhận. Tôi đã chuyển"
              </Link>
              .
            </Typography>
            <Button
              variant="contained"
              color="primary"
              LinkComponent={"a"}
              href={linkMess}
              target="_blank"
              rel="noreferrer"
            >
              Xác nhận. Tôi đã chuyển
            </Button>
            <Typography variant="body2" color={"primary"} fontStyle={"italic"}>
              Các giao dịch chuyển sai "Nội dung chuyển khoản" sẽ không được xử
              lý tự động. Hãy liên hệ Fanpage để được hỗ trợ.
            </Typography>
          </Stack>
        </Stack>
      }
    />
  );
};

export default ATMExchangeDialog;
